import React, { useState, useRef, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow, Grid2, Box } from '@mui/material';
import QuoteSubmissionSuccess from 'Components/QuoteSubmitSuccess';

import { tableCellClasses } from '@mui/material/TableCell';
import RoomForm from "../Components/CustomerRoomForm";
import { styled } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSubmitQuote } from '../hooks/quote';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewSharp'; // Import the icon
import { useValidateDiscountCode } from 'hooks/quote';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';

// Importing images from assets
import Mist_Ivory from '../assets/ZLF-Mist-Ivory.png';
import Mist_White from '../assets/ZLF-Mist-White.png';
import Mist_Grey from '../assets/ZLf-Mist-Grey.png';
//import Glory_White from '../assets/ZBO-Glory-White.png';
//import Glory_Ivory from '../assets/ZBO-Glory-Ivory.png';
//import Glory_Grey from '../assets/ZBO-Glory-Grey.png';
import Solaire_White from '../assets/SLF-Solaire-White.png';
import Solaire_Beige from '../assets/SLF-Solaire-Beige.png';
import Solaire_Grey from '../assets/SLF-Solaire-Grey.png';
import Solaire_LightGrey from '../assets/SLF-Solaire-LightGrey.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#235169",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function toFraction(decimal) {
  const tolerance = 1.0E-6;
  let h1 = 1, h2 = 0, k1 = 0, k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    const aux = h1; h1 = a * h1 + h2; h2 = aux;
    const auxk = k1; k1 = a * k1 + k2; k2 = auxk;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);

  return `${h1}/${k1}`;
}


const CustomerCurtainForm = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [discountCode, setDiscountCode] = useState("");
  const [respondedDiscount, setRespondedDiscount] = useState({})
  const [includeTax, setIncludeTax] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [delivery, setDelivery] = useState(false)
  const priceTableRef = useRef();
  const materialTableRef = useRef();
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false)
  const [quoteId, setQuoteId] = useState("")

  const { mutateAsync: submitQuoteForm, isLoading: isSubmitingQuoteForm } = useSubmitQuote();
  const { mutateAsync: validateDiscount, isLoading: isValidatingDiscount } = useValidateDiscountCode();
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(()=>{
    console.log("!!!!!!!!!!!!!!",totalPrice.toFixed(2))
    if(calculated && totalPrice.toFixed(2)!=NaN)
      handleSubmitQuote(totalPrice.toFixed(2));
  },[calculated])

  const [rooms, setRooms] = useState([{
    roomName: 'window1',
    curtainType: '',
    index: 1,
    fabric: '',
    height: '',
    width: '',
    unit: 'inches',
    installation: false,
    price: 0,
    widthFraction: 0,
    heightFraction: 0
  }]);

  const curtainTypes = {
    Zebra: [
        { name: 'Light-Filtering or Blackout, White', price: 120, imageUrl: Mist_White },
        { name: 'Light-Filtering or Blackout, Ivory, ', price: 120, imageUrl: Mist_Ivory },
        { name: 'Light-Filtering or Blackout, Grey', price: 120, imageUrl: Mist_Grey }
    ],
    "Roller Shades": [
        { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
        { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
        { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
    ],
    "Sun Screen": [
        { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
        { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
        { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
    ],
    "Sheer Shades": [
        { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
        { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
        { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
    ],
    "Vertical Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
        { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
        { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
    ],
    "Horizontal Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
        { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
        { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
    ],
    "Cellular Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
        { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
        { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
    ],
    "Roman Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
        { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
        { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
    ],
};

  const updateRoom = (index, updatedRoom) => {
    const updatedRooms = [...rooms];
    updatedRooms[index] = updatedRoom;
    setRooms(updatedRooms);
  };

  const removeRoom = (index) => {
    const updatedRooms = rooms.filter((_, roomIndex) => roomIndex !== index);
    setRooms(updatedRooms);
  };

  const duplicateRoom = (index) => {
    let baseRoom = rooms[index]
    setCalculated(false)
    setRooms([...rooms, {
      roomName: baseRoom.roomName + `_copy`,
      curtainType: baseRoom.curtainType || "",
      fabric: baseRoom.fabric || '',
      height: baseRoom.height || '',
      width: baseRoom.width || '',
      unit: baseRoom.unit || 'inches',
      motorized: baseRoom.motorized || false,
      price: 0,
      widthFraction: baseRoom.widthFraction || 0,
      heightFraction: baseRoom.heightFraction || 0
    }]);
  };

  const calculatePriceForRoom = (room) => {
    const { unit, height, width, fabric, motorized, curtainType, widthFraction, heightFraction } = room;
    const heightInMeters = unit === 'cm' ? (parseFloat(height) + parseFloat(heightFraction)) / 100 : (parseFloat(height) + parseFloat(heightFraction)) * 0.0254;
    let widthInMeters = unit === 'cm' ? (parseFloat(width) + parseFloat(widthFraction)) / 100 : (parseFloat(width) + parseFloat(widthFraction)) * 0.0254;


    if (widthInMeters < 1) widthInMeters = 1;

    const area = heightInMeters * widthInMeters * (curtainType==="zebra"?2:1);
    const selectedFabric = curtainTypes[curtainType]?.find(f => f.name === fabric);
    const fabricPrice = selectedFabric ? selectedFabric.price : 0;

    let price = area * fabricPrice;
    let basePrice = price;
    if (motorized) price += 150;
    // if (delivery) price += 50;
    // if (installation) price += 30;

    return { price, basePrice };
  };

  const validateDiscountCode = async () => {
    let validateDiscountResponse = await validateDiscount(discountCode)
    if (validateDiscountResponse.status === "OK"){
      setRespondedDiscount(validateDiscountResponse.discount);
      
    }
  }

  const handleSubmit = () => {
    let invalidRooms = rooms.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType)
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.")
      return
    }
    const updatedRooms = rooms.map(room => ({
      ...room,
      ...calculatePriceForRoom(room)
    }));
    setRooms(updatedRooms);
    setCalculated(true);
  };


  const handleSubmitQuote = (fixedTotalPrice) => {
    let invalidRooms = rooms.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType)
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.")
      return
    }

    // const updatedRooms = rooms.map(room => ({
    //   ...room,
    //   ...calculatePriceForRoom(room)
    // }));
    // setRooms(updatedRooms);
    // setCalculated(true);

    submitQuoteForm({
      customerName,
      formJSON: rooms,
      email: customerEmail,
      includeTax,
      delivery,
      installation,
      customerPhoneNumber,
      discountCode,
      discount:respondedDiscount,
      overallPrice: fixedTotalPrice
    }, {
      onSuccess: (response) => {
        setAlertOpen(true); // Show the success alert
        setSubmitted(true);
        setQuoteId(response.quoteId)
        console.log("response", response)
        setTimeout(()=>navigate(`/checkout/${response.quoteId}`),1000)

      }
    })
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false); // Close the alert when needed
  };

  const calculateTotalPrice = () => {
    let roomPrices = rooms.reduce((total, room) => total + parseFloat(room.price), 0);
    let deliveryPrice = delivery ? 50 : 0;
    let installationPrice = installation ? Math.max(50, rooms.length * 30) : 0;
    let totalPrice = roomPrices + deliveryPrice + installationPrice;
    return totalPrice.toFixed(2);
  };

  const calculateSubTotal = () => {
    console.log("rooms:", rooms)
    let roomTotal = rooms.reduce(
      (sum, room) => sum + room.basePrice + (room.motorized ? 150 : 0),
      0
    );
    let deliveryFee = delivery ? 50 : 0;
    let installationFee = installation ? Math.max(50, rooms.length * 30) : 0;

    return roomTotal + deliveryFee + installationFee;
  };

  const calculateTotalWithDiscountAndTax = (subTotal) => {
    const discount = (respondedDiscount?.amount || 0) / 100;
    const tax = includeTax ? 0.13 : 0;
    return subTotal * (1 - discount) * (1 + tax);
  };

  const subTotal = calculateSubTotal();
  const totalPrice = calculateTotalWithDiscountAndTax(subTotal);



  return (
    submitted ?
      <QuoteSubmissionSuccess onReturnToWebsite={() => window.location.replace("https://shadesnow.ca")} quoteId={quoteId} />

      :
      <Grid style={{ paddingBottom: "100px", paddingTop: "50px" }} container spacing={2}>

        <Grid item xs={12} style={{ display: "flex" }}>
          <Typography variant="h5">Shades Now Instant Quote system</Typography>
        </Grid>

       
        <Grid item xs={12}>
          {/* Room Forms */}
          <RoomForm
            rooms={rooms}
            updateRooms={setRooms}
            updateRoom={updateRoom}
            removeRoom={removeRoom}
            duplicateRoom={duplicateRoom}

          />
        </Grid>


        {/* Calculate Button */}
        <Grid item xs={12}>
          <Button variant="contained" fullWidth sx={{ backgroundColor: "#235169" }} color="primary" onClick={handleSubmit}>
          {/* <Button variant="contained" fullWidth sx={{ backgroundColor: "#235169" }} color="primary" onClick={handleSubmit}> */}
            Proceed to Checkout 
          </Button>
        </Grid>
        

        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleAlertClose} severity="success" variant="filled">
            Quote submitted successfully!
          </Alert>
        </Snackbar>

        </Grid>

  );
};

export default CustomerCurtainForm;
