import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import { tableCellClasses } from '@mui/material/TableCell';
import RoomForm from "../Components/RoomForm";
import { styled } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewSharp'; // Import the icon


import { useGetQuote, useSubmitQuote } from '../hooks/quote';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#235169",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function toFraction(decimal) {
  const tolerance = 1.0E-6;
  let h1 = 1, h2 = 0, k1 = 0, k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    const aux = h1; h1 = a * h1 + h2; h2 = aux;
    const auxk = k1; k1 = a * k1 + k2; k2 = auxk;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);
  if(h1===0) return ``; 
  return `${h1}/${k1}`;
}


const CurtainForm = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [includeTax, setIncludeTax] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const priceTableRef = useRef();
  const materialTableRef = useRef();
  const { quoteId } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();

  const { mutate: getQuote, isLoading } = useGetQuote();
  const { mutate: submitQuoteForm, isLoading: isSubmittingQuoteForm } = useSubmitQuote();


  const curtainTypes = useMemo(() => ({
    
      Zebra: [
          { name: 'Light-Filtering or Blackout, White', price: 120},
          { name: 'Light-Filtering or Blackout, Ivory, ', price: 120},
          { name: 'Light-Filtering or Blackout, Grey', price: 120},
      ],
      "Roller Shades": [
          { name: 'Light-Filtering or Blackout, White', price: 90},
          { name: 'Light-Filtering or Blackout, Beige', price: 90},
          { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
          { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Sun Screen": [
          { name: 'Light-Filtering or Blackout, White', price: 90},
          { name: 'Light-Filtering or Blackout, Beige', price: 90},
          { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
          { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Sheer Shades": [
          { name: 'Light-Filtering or Blackout, White', price: 90},
          { name: 'Light-Filtering or Blackout, Beige', price: 90},
          { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
          { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Vertical Blinds": [
          { name: 'Light-Filtering or Blackout, White', price: 90},
          { name: 'Light-Filtering or Blackout, Beige', price: 90},
          { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
          { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Horizontal Blinds": [
          { name: 'Light-Filtering or Blackout, White', price: 90},
          { name: 'Light-Filtering or Blackout, Beige', price: 90},
          { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
          { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Cellular Blinds": [
          { name: 'Light-Filtering or Blackout, White', price: 90},
          { name: 'Light-Filtering or Blackout, Beige', price: 90},
          { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
          { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Roman Blinds": [
          { name: 'Light-Filtering or Blackout, White', price: 90},
          { name: 'Light-Filtering or Blackout, Beige', price: 90},
          { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
          { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
  }), []);

  const rooms = useRef([{
    roomName: 'Living Room',
    curtainType: '',
    fabric: '',
    height: '',
    width: '',
    unit: 'inches',
    installation: false,
    motorized:false,
    price: 0,
    widthFraction: 0,
    heightFraction: 0
  }]);

  const calculatePriceForRoom = useCallback((room) => {
    const { unit, height, width, fabric, motorized, curtainType, widthFraction, heightFraction } = room;
    console.log(widthFraction, heightFraction);
    const heightInMeters = unit === 'cm' ? (parseFloat(height) + parseFloat(heightFraction)) / 100 : (parseFloat(height) + parseFloat(heightFraction)) * 0.0254;
    let widthInMeters = unit === 'cm' ? (parseFloat(width) + parseFloat(widthFraction)) / 100 : (parseFloat(width) + parseFloat(widthFraction)) * 0.0254;

    console.log(heightInMeters, widthInMeters);

    if (widthInMeters < 1) widthInMeters = 1;

    const area = heightInMeters * widthInMeters * (curtainType==="zebra"?2:1);
    const selectedFabric = curtainTypes[curtainType]?.find(f => f.name === fabric);
    const fabricPrice = selectedFabric ? selectedFabric.price : 0;

    let price = area * fabricPrice;
    let basePrice = price;
    if (motorized) price += 150;

    return { price, basePrice };
  },[curtainTypes]);

  const handleSubmit = () => {
    // console.log("rooms:", rooms.current);
    let invalidRooms = rooms.current.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType);
    // console.log(invalidRooms);
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.");
      return;
    }
    const updatedRooms = rooms.current.map(room => ({
      ...room,
      ...calculatePriceForRoom(room)
    }));
    // console.log("updated rooms", updatedRooms);
    rooms.current = updatedRooms;
    // setRooms("updatedRooms: ",updatedRooms);
    setCalculated(true);
  }


  useEffect(() => {
    if(!quoteId) return;
    // console.log("saaalaaa, ", quoteId);
    getQuote(quoteId, {
      onSuccess: (response) => {
        if (response.status === "OK") {
          const quote = response.quote;
          console.log("Quote: ", quote);
          rooms.current = quote.formJSON;
          // setRooms(quote.formJSON);
          setCustomerEmail(quote.email);
          setCustomerName(quote.customerName);
          setCustomerPhoneNumber(quote.customerPhoneNumber);
          setIncludeTax(quote.includeTax);
          setDelivery(quote.delivery);
          setInstallation(quote.installation);
          handleSubmit();
          // setTimeout(() => handleSubmit(), 1500);
        }
      }
    });
  }, [quoteId]);

  

  

  const updateRoom = (index, updatedRoom) => {
    const updatedRooms = [...rooms.current];
    updatedRooms[index] = updatedRoom;
    rooms.current = updatedRooms;
  };


  const addRoom = () => {
    setCalculated(false);
    rooms.current.push({
      roomName: `Room ${rooms.length + 1}`,
      curtainType: '',
      fabric: '',
      height: '',
      width: '',
      unit: 'inches',
      motorized: false,
      price: 0,
      widthFraction: 0,
      heightFraction: 0
    });
  };


  // useEffect(() => {
  //   console.log("room have been updated", rooms);
  // },[rooms])

  const removeRoom = (index) => {
    const updatedRooms = rooms.current.filter((_, roomIndex) => roomIndex !== index);
    rooms.current = updatedRooms;
  };

  const handleSubmitQuote = () => {
    let invalidRooms = rooms.current.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType);
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.");
      return;
    }

    const updatedRooms = rooms.current.map(room => ({
      ...room,
      ...calculatePriceForRoom(room)
    }));
    rooms.current = updatedRooms;
    setCalculated(true);

    submitQuoteForm({
      customerName,
      formJSON: rooms.current,
      email: customerEmail,
      includeTax,
      delivery,
      installation,
    }, {
      onSuccess: (response) => {
        setAlertOpen(true); // Show the success alert
      }
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false); // Close the alert when needed
  };

  const calculateTotalPrice = () => {
    let roomPrices = rooms.current.reduce((total, room) => total + parseFloat(room.price), 0);
    let deliveryPrice = delivery ? 50 : 0;
    let installationPrice = installation ? Math.max(50, rooms.current.length * 30) : 0;
    let totalPrice = roomPrices + deliveryPrice + installationPrice;
    return totalPrice.toFixed(2);
  };

  return (
    <Grid style={{ paddingBottom: "100px", paddingTop: "50px" }} container spacing={2}>
      {/* Customer Information */}
      <Grid item xs={12} style={{display:"flex"}}>
      <Button style={{marginRight:"10px", minWidth:"30px"}} color="primary" variant="contained" onClick={() => navigate(`/`)}>
          <ArrowBackIcon />
        </Button>
        <Typography variant="h5">Curtain Price Calculator</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Customer Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Customer Email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="PhoneNumber"
          value={customerPhoneNumber}
          onChange={(e) => setCustomerPhoneNumber(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        {rooms.current.length>0 && rooms.current.map((roomData, index) => (
          <RoomForm
            key={index}
            roomIndex={index}
            disabled={true}
            roomData={roomData}
            updateRoom={updateRoom}
            removeRoom={removeRoom}
          />
        ))}
      </Grid>
      {/* Add Room Button */}
      {/* <Grid item xs={12}>
        <Button fullWidth variant="contained" sx={{ backgroundColor: "#235169" }} color="primary" onClick={addRoom}>
          <AddCircleOutline /> Add Room
        </Button>
      </Grid> */}

      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={includeTax} disabled onChange={(e) => setIncludeTax(e.target.checked)} />}
          label="Include Tax"
        />
        <FormControlLabel
          control={<Checkbox checked={delivery} disabled onChange={(e) => setDelivery(e.target.checked)} />}
          label="Delivery"
        />
        <FormControlLabel
          control={<Checkbox checked={installation} disabled onChange={(e) => setInstallation(e.target.checked)} />}
          label="Installation"
        />
      </Grid>

      <Grid item xs={12}>
        <Button fullWidth variant="contained" sx={{ backgroundColor: "#235169" }} onClick={handleSubmit}>
          Calculate Price
        </Button>
      </Grid>

      {calculated && (
        <Grid item xs={12}>
          <Typography variant="h6">Price Breakdown</Typography>
          <Table aria-label="price breakdown" ref={priceTableRef}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Room</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Fabric</StyledTableCell>
                <StyledTableCell>Width</StyledTableCell>
                <StyledTableCell>Height</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rooms.current.map((room, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{room.roomName}</StyledTableCell>
                  <StyledTableCell>{room.curtainType}</StyledTableCell>
                  <StyledTableCell>{room.fabric}</StyledTableCell>
                  <StyledTableCell>{`${room.width} ${toFraction(room.widthFraction)}`}</StyledTableCell>
                  <StyledTableCell>{`${room.height} ${toFraction(room.heightFraction)}`}</StyledTableCell>
                  <StyledTableCell>{`$${room.price.toFixed(2)}`}</StyledTableCell>
                </StyledTableRow>
              ))}
              {delivery && <StyledTableRow>
                  <StyledTableCell colSpan={5}>Delivery</StyledTableCell>
                  <StyledTableCell>$50</StyledTableCell>
                </StyledTableRow>}

                {installation && <StyledTableRow>
                  <StyledTableCell colSpan={5}>Installation (Delivery Included)</StyledTableCell>
                  <StyledTableCell>${Math.max(50, rooms.length * 30)}</StyledTableCell>
                </StyledTableRow>}


                <StyledTableRow>
                  <StyledTableCell colSpan={5}>Sub-total Price</StyledTableCell>
                  <StyledTableCell>${calculateTotalPrice()}</StyledTableCell>
                </StyledTableRow>
                {includeTax && <StyledTableRow>
                  <StyledTableCell colSpan={5}>HST (13%)</StyledTableCell>
                  <StyledTableCell>${(calculateTotalPrice() * 0.13).toFixed(2)}</StyledTableCell>
                </StyledTableRow>}
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>Total Price</StyledTableCell>
                  <StyledTableCell>${(calculateTotalPrice() * (includeTax ? 1.13 : 1)).toFixed(2)}</StyledTableCell>
                </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
      )}

      {/* <Grid item xs={12}>
        <Button fullWidth variant="contained" sx={{ backgroundColor: "#235169" }} onClick={handleSubmitQuote}>
          Submit Quote
        </Button>
      </Grid> */}

      {/* Success Alert */}
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Quote submitted successfully!
        </Alert>
      </Snackbar>

    </Grid>
  );
};

export default CurtainForm;
