import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Box
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewSharp'; // Import the icon


import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";

import { useGetQuote } from "../hooks/quote";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#235169",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function toFraction(decimal) {
  const tolerance = 1.0e-6;
  let h1 = 1,
    h2 = 0,
    k1 = 0,
    k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    const aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    const auxk = k1;
    k1 = a * k1 + k2;
    k2 = auxk;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);
  if (h1 === 0) return ``;
  return `${h1}/${k1}`;
}

const CurtainForm = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("")
  const [includeTax, setIncludeTax] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const priceTableRef = useRef();
  const { quoteId } = useParams();

  const navigate = useNavigate();

  const { mutate: getQuote, isLoading } = useGetQuote();

  const curtainTypes = useMemo(
    () => ({
      Zebra: [
        { name: 'Light-Filtering or Blackout, White', price: 120},
        { name: 'Light-Filtering or Blackout, Ivory, ', price: 120},
        { name: 'Light-Filtering or Blackout, Grey', price: 120},
      ],
      "Roller Shades": [
        { name: 'Light-Filtering or Blackout, White', price: 90},
        { name: 'Light-Filtering or Blackout, Beige', price: 90},
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
        { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Sun Screen": [
        { name: 'Light-Filtering or Blackout, White', price: 90},
        { name: 'Light-Filtering or Blackout, Beige', price: 90},
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
        { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Sheer Shades": [
        { name: 'Light-Filtering or Blackout, White', price: 90},
        { name: 'Light-Filtering or Blackout, Beige', price: 90},
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
        { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Vertical Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90},
        { name: 'Light-Filtering or Blackout, Beige', price: 90},
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
        { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Horizontal Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90},
        { name: 'Light-Filtering or Blackout, Beige', price: 90},
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
        { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Cellular Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90},
        { name: 'Light-Filtering or Blackout, Beige', price: 90},
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
        { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
      "Roman Blinds": [
        { name: 'Light-Filtering or Blackout, White', price: 90},
        { name: 'Light-Filtering or Blackout, Beige', price: 90},
        { name: 'Light-Filtering or Blackout, LightGrey', price: 90},
        { name: 'Light-Filtering or Blackout, Grey', price: 90},
      ],
    }),
    []
  );

  const rooms = useRef([
    {
      roomName: "Living Room",
      curtainType: "",
      fabric: "",
      height: "",
      width: "",
      unit: "inches",
      installation: false,
      price: 0,
      widthFraction: 0,
      heightFraction: 0,
    },
  ]);

  const calculatePriceForRoom = useCallback(
    (room) => {
      const {
        unit,
        height,
        width,
        fabric,
        motorized,
        curtainType,
        widthFraction,
        heightFraction,
      } = room;
      console.log(widthFraction, heightFraction);
      const heightInMeters =
        unit === "cm"
          ? (parseFloat(height) + parseFloat(heightFraction)) / 100
          : (parseFloat(height) + parseFloat(heightFraction)) * 0.0254;
      let widthInMeters =
        unit === "cm"
          ? (parseFloat(width) + parseFloat(widthFraction)) / 100
          : (parseFloat(width) + parseFloat(widthFraction)) * 0.0254;

      console.log(heightInMeters, widthInMeters);

      if (widthInMeters < 1) widthInMeters = 1;

      const area = heightInMeters * widthInMeters * (curtainType === "zebra" ? 2 : 1);
      const selectedFabric = curtainTypes[curtainType]?.find(
        (f) => f.name === fabric
      );
      const fabricPrice = selectedFabric ? selectedFabric.price : 0;

      let price = area * fabricPrice;
      let basePrice = price;
      if (motorized) price += 150;

      return { price, basePrice };
    },
    [curtainTypes]
  );

  const handleSubmit = () => {
    // console.log("rooms:", rooms.current);
    let invalidRooms = rooms.current.filter(
      (room) => !room.width || !room.height || !room.fabric || !room.curtainType
    );
    // console.log(invalidRooms);
    if (invalidRooms.length > 0) {
      alert(
        "Please fill all required fields(width, height, type, fabric) for each room."
      );
      return;
    }
    const updatedRooms = rooms.current.map((room) => ({
      ...room,
      ...calculatePriceForRoom(room),
    }));
    rooms.current = updatedRooms;
    setCalculated(true);
  };

  useEffect(() => {
    if (!quoteId) return;
    getQuote(quoteId, {
      onSuccess: (response) => {
        if (response.status === "OK") {
          const quote = response.quote;
          console.log("Quote: ", quote);
          rooms.current = quote.formJSON;
          setCustomerEmail(quote.email);
          setCustomerPhoneNumber(quote.customerPhoneNumber);
          setCustomerName(quote.customerName);
          setIncludeTax(quote.includeTax);
          setDelivery(quote.delivery);
          setInstallation(quote.installation);
          handleSubmit();
        }
      },
    });
  }, [quoteId]);

  const calculateTotalPrice = () => {
    let roomPrices = rooms.current.reduce(
      (total, room) => total + parseFloat(room.price),
      0
    );
    let deliveryPrice = delivery ? 50 : 0;
    let installationPrice = installation
      ? Math.max(50, rooms.current.length * 30)
      : 0;
    let totalPrice = roomPrices + deliveryPrice + installationPrice;
    return totalPrice.toFixed(2);
  };

  return isLoading ? (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <CircularProgress />:
    </Box>
  ) : (
    <Grid
      style={{ paddingBottom: "100px", paddingTop: "50px" }}
      container
      spacing={2}
    >
      {/* Customer Information */}
      <Grid item xs={12} style={{ display: "flex" }}>
        <Button style={{ marginRight: "10px", minWidth: "30px" }} color="primary" variant="contained" onClick={() => navigate(`/`)}>
          <ArrowBackIcon />
        </Button>
        <Typography variant="h5">Estimate Details</Typography>
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography>Name: {customerName}</Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography>Email Address: {customerEmail}</Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography>Phone: {customerPhoneNumber}</Typography>
      </Grid>

      {calculated && (
        <Grid item xs={12}>
          <Typography variant="h6">Price Breakdown</Typography>
          <Table aria-label="price breakdown" ref={priceTableRef}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Room</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Fabric</StyledTableCell>
                <StyledTableCell>Width</StyledTableCell>
                <StyledTableCell>Height</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rooms.current.map((room, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{room.index}</StyledTableCell>
                  <StyledTableCell>{room.roomName}</StyledTableCell>
                  <StyledTableCell>{room.curtainType}</StyledTableCell>
                  <StyledTableCell>{room.fabric}</StyledTableCell>
                  <StyledTableCell>{`${room.width} ${toFraction(
                    room.widthFraction
                  )}`}</StyledTableCell>
                  <StyledTableCell>{`${room.height} ${toFraction(
                    room.heightFraction
                  )}`}</StyledTableCell>
                  <StyledTableCell>{`$${room.price.toFixed(
                    2
                  )}`}</StyledTableCell>
                </StyledTableRow>
              ))}
              {delivery && <StyledTableRow>
                <StyledTableCell colSpan={6}>Delivery</StyledTableCell>
                <StyledTableCell>$50</StyledTableCell>
              </StyledTableRow>}

              {installation && <StyledTableRow>
                <StyledTableCell colSpan={6}>Installation (Delivery Included)</StyledTableCell>
                <StyledTableCell>${Math.max(50, rooms.current.length * 30)}</StyledTableCell>
              </StyledTableRow>}


              <StyledTableRow>
                <StyledTableCell colSpan={6}>Sub-total Price</StyledTableCell>
                <StyledTableCell>${calculateTotalPrice()}</StyledTableCell>
              </StyledTableRow>
              {includeTax && <StyledTableRow>
                <StyledTableCell colSpan={6}>HST (13%)</StyledTableCell>
                <StyledTableCell>${(calculateTotalPrice() * 0.13).toFixed(2)}</StyledTableCell>
              </StyledTableRow>}
              <StyledTableRow>
                <StyledTableCell colSpan={6}>Total Price</StyledTableCell>
                <StyledTableCell>${(calculateTotalPrice() * (includeTax ? 1.13 : 1)).toFixed(2)}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography>{new Date().toLocaleDateString()}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          This price estimate is valid for 15 days from this date.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CurtainForm;
