import React from 'react';
import StripeContainer from '../Components/PaymentForm';

const PaymentPage = () => {
    return (
        <div>
            <h1>Complete Your Payment</h1>
            <StripeContainer />
        </div>
    );
};

export default PaymentPage;