import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';

const SelectionGrid = ({
    items = [],
    selectedItem,
    onSelect,
    onCancel = ()=>{},
    title,
    cardBorderColor = '#235169',
    defaultBorderColor = 'grey',
}) => {
    return (
        <Grid item xs={120}>
            {title && (
                <Typography variant="subtitle1" gutterBottom>
                    {title}
                </Typography>
            )}
                
                <Grid container spacing={2}>
                    {items?.map((item) => (
                        <Grid item xs={4} sm={3} md={2} key={item.name}>
                            <Card
                                onClick={() => {
                                    onSelect(item)
                                }}
                                sx={{
                                    border: selectedItem === item.name ? `6px solid ${cardBorderColor}` : `1px solid ${defaultBorderColor}`,
                                }}
                            >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={item.imageUrl}
                                        alt={item.name}
                                    />
                                    <CardContent>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.name}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
        </Grid>
    );
};

export default SelectionGrid;