import React from 'react';
import { TextField, FormControlLabel, Checkbox, MenuItem, Select, Grid, Typography, Button, Divider } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';


const inchFractions = [
    { label: '0', value: 0 },
    { label: '1/8', value: 1 / 8 },
    { label: '1/4', value: 1 / 4 },
    { label: '3/8', value: 3 / 8 },
    { label: '1/2', value: 1 / 2 },
    { label: '5/8', value: 5 / 8 },
    { label: '3/4', value: 3 / 4 },
    { label: '7/8', value: 7 / 8 }
];

const millimeters = Array.from({ length: 10 }, (_, i) => i);

// A single room's curtain form component
const RoomForm = ({ roomIndex, roomData, updateRoom, removeRoom, duplicateRoom, disabled }) => {
    const { roomName, curtainType, fabric, height, heightFraction, width, widthFraction, unit, motorized } = roomData;

    const curtainTypes = {
        Zebra: [
            { name: 'Aurora', price: 80 },
            { name: 'Lumina', price: 120 },
            { name: 'Pearl', price: 95 },
        ],
        Shade: [
            { name: 'MC', price: 60 },
            { name: 'PV', price: 90 },
            { name: 'NC', price: 80 },
        ],
    };

    const handleInputChange = (field, value) => {
        updateRoom(roomIndex, { ...roomData, [field]: value });
    };

    return (
        <Grid container spacing={1} columns={120}>
            <Grid item xs={120}>

                <Grid container columns={20} spacing={1} style={{ paddingBottom: "3%", paddingTop: "7%" }}>
                    {roomIndex > 0 && (
                        <Grid item xs={3} md={1}>
                            <Button variant="contained" sx={{ minWidth: 0, width: "30px", height: "30px" }} onClick={() => removeRoom(roomIndex)} color="error">
                                <DeleteOutlined />
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={3} md={1}>
                        <Button variant="contained" sx={{ minWidth: 0, width: "30px", height: "30px" }} onClick={() => duplicateRoom(roomIndex)} color="inherit">
                            <ContentCopyOutlinedIcon />
                        </Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={120}>
                <TextField
                    label="Room Name"
                    value={roomName}
                    onChange={(e) => handleInputChange('roomName', e.target.value)}
                    fullWidth
                />
            </Grid>

            <Grid item xs={curtainType ? 40 : 120} md={curtainType ? 50 : 120}>
                <Select
                    value={curtainType}
                    onChange={(e) => handleInputChange('curtainType', e.target.value)}
                    fullWidth
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        Select Fabric Type
                    </MenuItem>
                    <MenuItem value="Zebra">Zebra</MenuItem>
                    <MenuItem value="Shade">Roller Shade</MenuItem>
                </Select>
            </Grid>

            {curtainType && (
                <Grid item xs={80} md={70}>
                    <Select
                        value={fabric}
                        onChange={(e) => handleInputChange('fabric', e.target.value)}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select Fabric
                        </MenuItem>
                        {curtainTypes[curtainType].map(fabric => (
                            <MenuItem key={fabric.name} value={fabric.name}>
                                {fabric.name} (${fabric.price} per m²)
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            )}

            <Grid item xs={21} md={50}>
                <TextField
                    label="W"
                    value={width}
                    type='number'
                    onChange={(e) => {
                        const value = e.target.value;
                        let filteredValue = value.replace(/[^0-9]/g, "");
                        handleInputChange('width', filteredValue);

                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={26} md={10}>
                <Select
                    value={widthFraction || 0}
                    onChange={(e) => handleInputChange('widthFraction', e.target.value)}
                    fullWidth
                >
                    {unit === 'inches'
                        ? inchFractions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))
                        : millimeters.map(mm => (
                            <MenuItem key={mm} value={mm}>
                                {mm} mm
                            </MenuItem>
                        ))
                    }
                </Select>
            </Grid>

            <Grid item xs={21} md={50}>
                <TextField
                    label="H"
                    value={height}
                    type='number'
                    onChange={(e) => {
                        const value = e.target.value;
                        let filteredValue = value.replace(/[^0-9]/g, "");
                        handleInputChange('height', filteredValue);
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={26} md={10}>
                <Select
                    value={heightFraction || 0}
                    onChange={(e) => handleInputChange('heightFraction', e.target.value)}
                    fullWidth
                >
                    {unit === 'inches'
                        ? inchFractions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))
                        : millimeters.map(mm => (
                            <MenuItem key={mm} value={mm}>
                                {mm} mm
                            </MenuItem>
                        ))
                    }
                </Select>
            </Grid>

            <Grid item xs={26} md={120}>
                <Select
                    value={unit}
                    onChange={(e) => handleInputChange('unit', e.target.value)}
                    fullWidth
                >
                    <MenuItem value="inches">In</MenuItem>
                    <MenuItem value="cm">CM</MenuItem>
                </Select>
            </Grid>

            <Grid item xs={120}>
                <FormControlLabel
                    control={<Checkbox disabled={disabled} checked={motorized} onChange={(e) => {
                        handleInputChange('motorized', e.target.checked)
                    }} />}
                    label="Motorized"
                />
                <Divider />
            </Grid>
        </Grid>
    );
};

export default RoomForm;