import React, { useState } from 'react';
import { StepContent, Stepper, Step, StepLabel, TextField, FormControlLabel, Checkbox, MenuItem, Select, Grid, Typography, Button, Divider, Card, CardMedia, CardActionArea, CardContent, IconButton, Stack } from '@mui/material';
import { DeleteOutlined, Margin } from '@mui/icons-material';
import ContentCopyOutlinedIcon from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SelectionGrid from './SelectionGrid';


// Importing images from assets
import Mist_Ivory from '../assets/ZLF-Mist-Ivory.png';
import Mist_White from '../assets/ZLF-Mist-White.png';
import Mist_Grey from '../assets/ZLf-Mist-Grey.png';
//import Glory_White from '../assets/ZBO-Glory-White.png';
//import Glory_Ivory from '../assets/ZBO-Glory-Ivory.png';
//import Glory_Grey from '../assets/ZBO-Glory-Grey.png';
import Solaire_White from '../assets/SLF-Solaire-White.png';
import Solaire_Beige from '../assets/SLF-Solaire-Beige.png';
import Solaire_Grey from '../assets/SLF-Solaire-Grey.png';
import Solaire_LightGrey from '../assets/SLF-Solaire-LightGrey.png';

import Zebra from '../assets/zebra.webp';
import Roller from '../assets/roller.webp';
import Cellular from '../assets/cellular.webp';
import Sheer from '../assets/sheer.webp';
import Vertical from '../assets/vertical.webp';
import Horizontal from '../assets/horizontal.webp';
import Roman from '../assets/roman.webp';
import SunScreen from '../assets/sunscreen.webp'

const inchFractions = [
    { label: '0', value: 0 },
    { label: '1/8', value: 1 / 8 },
    { label: '1/4', value: 1 / 4 },
    { label: '3/8', value: 3 / 8 },
    { label: '1/2', value: 1 / 2 },
    { label: '5/8', value: 5 / 8 },
    { label: '3/4', value: 3 / 4 },
    { label: '7/8', value: 7 / 8 }
];

const inverseFraction = (value) => {
    const target = inchFractions.filter((row) => Math.abs(row.value - value) < 1E-10)[0]

    return target.label !== "0" ? target.label : "";
}
const millimeters = Array.from({ length: 10 }, (_, i) => i);




const RoomForm = ({ rooms = [], updateRooms }) => {
    const [currentPage, setCurrentPage] = useState(0);
    // const [currentPage, setCurrentPage] = useState(0);
    const [startIndex, setStartIndex] = useState(0); // Start index for paginated room navigation
    // const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: 'Product Selection',
            description: 'Choose the type of product (e.g., Zebra, Roller Shades, etc.) you want to install in the room.',
        },
        {
            label: 'Fabric Selection',
            description: 'Select the fabric for the chosen product. Options depend on the product type.',
        },
        {
            label: 'Measurements',
            description: 'Provide the width and height of the product along with the measurement unit.',
        },
        {
            label: 'Additional Options',
            description: 'Choose additional options such as motorization or other customizations.',
        },
    ];

    const products = [
        { name: "Zebra", imageUrl: Zebra },
        { name: "Roller Shades", imageUrl: Roller },
        { name: "Sun Screen", imageUrl: SunScreen },
        { name: "Sheer Shades", imageUrl: Sheer },
        { name: "Vertical Blinds", imageUrl: Vertical },
        { name: "Horizontal Blinds", imageUrl: Horizontal },
        { name: "Cellular Blinds", imageUrl: Cellular },
        { name: "Roman Blinds", imageUrl: Roman },
    ]
    const curtainTypes = {
        Zebra: [
            { name: 'Light-Filtering or Blackout, White', price: 120, imageUrl: Mist_White },
            { name: 'Light-Filtering or Blackout, Ivory, ', price: 120, imageUrl: Mist_Ivory },
            { name: 'Light-Filtering or Blackout, Grey', price: 120, imageUrl: Mist_Grey }
        ],
        "Roller Shades": [
            { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
            { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
            { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
            { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
        ],
        "Sun Screen": [
            { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
            { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
            { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
            { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
        ],
        "Sheer Shades": [
            { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
            { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
            { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
            { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
        ],
        "Vertical Blinds": [
            { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
            { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
            { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
            { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
        ],
        "Horizontal Blinds": [
            { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
            { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
            { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
            { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
        ],
        "Cellular Blinds": [
            { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
            { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
            { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
            { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
        ],
        "Roman Blinds": [
            { name: 'Light-Filtering or Blackout, White', price: 90, imageUrl: Solaire_White },
            { name: 'Light-Filtering or Blackout, Beige', price: 90, imageUrl: Solaire_Beige },
            { name: 'Light-Filtering or Blackout, LightGrey', price: 90, imageUrl: Solaire_LightGrey },
            { name: 'Light-Filtering or Blackout, Grey', price: 90, imageUrl: Solaire_Grey }
        ],
    };

    const MAX_VISIBLE_BUTTONS = 5;

    if (rooms.length === 0) {
        rooms.push({
            roomName: 'window1',
            curtainType: '',
            fabric: '',
            height: '',
            heightFraction: 0,
            width: '',
            widthFraction: 0,
            unit: 'inches',
            motorized: false,
            activeStep: 0,
        });
    }

    const handleInputChange = (update) => {
        const updatedRooms = [...rooms];
        if (update.unit) {
            updatedRooms[currentPage] = { ...rooms[currentPage], ...update, widthFraction: 0, heightFraction: 0 };

        }
        else
            updatedRooms[currentPage] = { ...rooms[currentPage], ...update };

        updateRooms(updatedRooms);
    };

    const addRoom = () => {
        const newRoom = {
            roomName: `window${rooms.length+1}`,
            curtainType: '',
            fabric: '',
            height: '',
            heightFraction: 0,
            width: '',
            widthFraction: 0,
            unit: 'inches',
            motorized: false,
            activeStep: 0
        };
        updateRooms([...rooms, newRoom]);
        setCurrentPage(rooms.length);
    };

    const removeCurrentRoom = () => {
        if (rooms.length > 1) {
            const updatedRooms = rooms.filter((_, index) => index !== currentPage);
            updateRooms(updatedRooms);
            setCurrentPage((prev) => (prev > 0 ? prev - 1 : 0));
        }
    };

    const getVisibleRange = () => {
        const totalRooms = rooms.length;
        const start = Math.max(
            Math.min(currentPage - Math.floor(MAX_VISIBLE_BUTTONS / 2), totalRooms - MAX_VISIBLE_BUTTONS),
            0
        );
        const end = Math.min(start + MAX_VISIBLE_BUTTONS, totalRooms);
        return { start, end };
    };

    const { start, end } = getVisibleRange();

    const duplicateCurrentRoom = () => {
        const duplicatedRoom = { ...rooms[currentPage] };
        const updatedRooms = [...rooms];
        updatedRooms.splice(currentPage + 1, 0, duplicatedRoom);
        updateRooms(updatedRooms);
        setCurrentPage(currentPage + 1);
    };

    const navigateToRoom = (index) => {
        if (index >= 0 && index < rooms.length) {
            setCurrentPage(index);
        }
    };

    const visibleRange = () => {
        const range = [];
        if (currentPage > 0) {
            range.push(currentPage - 1);
        }
        range.push(currentPage);
        if (currentPage < rooms.length - 1) {
            range.push(currentPage + 1);
        }
        return range;
    };

    return (
        <Grid container spacing={2} columns={120}>

            {/* Room Navigation */}
            <Grid item xs={10}>
                {/* Prev Button */}
                <Button
                    variant="outlined"
                    disabled={currentPage === 0}
                    onClick={() => setCurrentPage(currentPage - 1)}
                >
                    Prev
                </Button>
            </Grid>
            <Grid item xs={100} >
                <Stack direction="row" spacing={1} alignItems="center" style={{ width: "100%", justifyContent: "center" }}>



                    {/* Ellipsis at the beginning */}
                    {start > 0 && <Typography variant="body2">...</Typography>}

                    {/* Visible Room Buttons */}
                    {rooms.slice(start, end).map((room, index) => {
                        const roomIndex = start + index;
                        const fabric = rooms[roomIndex]?.fabric || 'None';
                        const fabricUrl = rooms[roomIndex]?.fabricUrl || 'None';
                        const size = rooms[roomIndex]?.height && rooms[roomIndex]?.width
                            ? rooms[roomIndex]?.unit === "inches"
                                ? `${rooms[roomIndex].width} ${inverseFraction(rooms[roomIndex].widthFraction) || ""} × ${rooms[roomIndex].height} ${inverseFraction(rooms[roomIndex].heightFraction) || ""}`
                                : `${rooms[roomIndex].width}${rooms[roomIndex].widthFraction ? `.${rooms[roomIndex].widthFraction}` : ""} × ${rooms[roomIndex].height}${rooms[roomIndex].heightFraction ? `.${rooms[roomIndex].heightFraction}` : ""}`
                            : 'No Size';

                        return (
                            <Button
                                key={roomIndex}
                                variant={roomIndex === currentPage ? "contained" : "outlined"}
                                color={roomIndex === currentPage ? "primary" : "default"}
                                onClick={() => setCurrentPage(roomIndex)}
                                sx={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // alignItems: 'flex-start',
                                }}
                            >
                                <Typography variant="body2">
                                    {rooms[roomIndex].roomName || `Room ${roomIndex + 1}`}
                                </Typography>
                                {fabricUrl !== "None" && <CardMedia
                                    component="img"
                                    height="110"
                                    image={fabricUrl}
                                    alt={fabric}
                                />}
                                <Typography variant="caption" color="body2">
                                    {size}
                                </Typography>
                            </Button>
                        );
                    })}

                    {/* Ellipsis at the end */}
                    {end < rooms.length && <Typography variant="body2">...</Typography>}


                </Stack>
            </Grid>

            <Grid item xs={10}>
                {/* Next Button */}
                <Button
                    variant="outlined"
                    disabled={currentPage === rooms.length - 1}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    Next
                </Button>
            </Grid>


            {/* Room Form */}
            <Grid item xs={120}>
                <TextField
                    label="Window Name"
                    value={rooms[currentPage]?.roomName || ''}
                    onChange={(e) => handleInputChange({ 'roomName': e.target.value })}
                    fullWidth
                />
            </Grid>

            <Grid item xs={120}>
                <Stepper activeStep={rooms[currentPage]?.activeStep || 0} orientation="vertical" sx={{ marginTop: '30px', marginBottom: '10px' }}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel  onClick={()=>handleInputChange({activeStep:index})} sx={{cursor:"pointer"}}>{step.label}</StepLabel>
                            <StepContent>

                                <Grid container spacing={2} mb={4}>
                                    {rooms[currentPage]?.activeStep > 0 && <Grid item xs={3}>

                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => {
                                                handleInputChange({ activeStep: rooms[currentPage]?.activeStep - 1 });
                                            }}
                                            disabled={rooms[currentPage]?.activeStep <= 0}
                                        >
                                            {(steps[index - 1]?.label?`BACK (${steps[index - 1]?.label})`:"BACK")}
                                        </Button>

                                    </Grid>
                                    }

                                </Grid>
                                <Typography>{step.description}</Typography>

                                {index === 0 && (
                                    <SelectionGrid
                                        title="Select Product:"
                                        items={products || []}
                                        selectedItem={rooms[currentPage]?.curtainType}
                                        onCancel={() => { handleInputChange({ activeStep: 0, curtainType: '' }) }}
                                        onSelect={(productOption) => {
                                            handleInputChange({
                                                curtainType: productOption.name,
                                                activeStep: 1
                                            })
                                        }
                                        }
                                    />
                                )}

                                {index === 1 && rooms[currentPage]?.curtainType && (
                                    <SelectionGrid
                                        title="Select Fabric:"
                                        items={curtainTypes[rooms[currentPage]?.curtainType] || []}
                                        selectedItem={rooms[currentPage]?.fabric}
                                        onCancel={() => handleInputChange({ activeStep: 1, fabric: '', fabricUrl: '' })}
                                        onSelect={(fabricOption) => {
                                            handleInputChange({
                                                fabric: fabricOption.name,
                                                fabricUrl: fabricOption.imageUrl,
                                                activeStep: 2
                                            })
                                        }
                                        }
                                    />

                                )}

                                {index === 2 &&
                                    <Grid container spacing={2} columns={120}>
                                        <Grid item xs={21} md={50}>
                                            <TextField
                                                label="W"
                                                value={rooms[currentPage].width}
                                                type="number"
                                                onChange={(e) => handleInputChange({ 'width': e.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={26} md={10}>
                                            <Select
                                                value={rooms[currentPage].widthFraction || 0}
                                                onChange={(e) => handleInputChange({ 'widthFraction': e.target.value })}
                                                fullWidth
                                            >
                                                {rooms[currentPage].unit === 'inches'
                                                    ? inchFractions.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))
                                                    : millimeters.map(mm => (
                                                        <MenuItem key={mm} value={mm}>
                                                            {mm} mm
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>

                                        <Grid item xs={21} md={50}>
                                            <TextField
                                                label="H"
                                                value={rooms[currentPage].height}
                                                type="number"
                                                onChange={(e) => handleInputChange({ 'height': e.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={26} md={10}>
                                            <Select
                                                value={rooms[currentPage].heightFraction || 0}
                                                onChange={(e) => handleInputChange({ 'heightFraction': e.target.value })}
                                                fullWidth
                                            >
                                                {rooms[currentPage].unit === 'inches'
                                                    ? inchFractions.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))
                                                    : millimeters.map(mm => (
                                                        <MenuItem key={mm} value={mm}>
                                                            {mm} mm
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Grid>

                                        <Grid item xs={26} md={120}>
                                            <Select
                                                value={rooms[currentPage].unit}
                                                onChange={(e) => handleInputChange({ 'unit': e.target.value })}
                                                fullWidth
                                            >
                                                <MenuItem value="inches">In</MenuItem>
                                                <MenuItem value="cm">CM</MenuItem>
                                            </Select>
                                        </Grid>


                                    </Grid>
                                }

                                {index === 3 && <Grid item xs={120}>
                                    <FormControlLabel
                                        control={<Checkbox checked={rooms[currentPage].motorized} onChange={(e) => handleInputChange({ 'motorized': e.target.checked })} />}
                                        label="Motorized"
                                    />
                                    <Divider />
                                </Grid>}

                                <Grid container spacing={2} mt={4}>
                                    <Grid item xs={3}>
                                        {rooms[currentPage]?.activeStep < steps.length - 1 &&
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    handleInputChange({ activeStep: (rooms[currentPage]?.activeStep || 0) + 1 });
                                                }}
                                            >
                                                NEXT ({steps[index + 1]?.label})
                                            </Button>
                                        }

                                        {rooms[currentPage]?.activeStep >= steps.length - 1 &&
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={addRoom}
                                            // disabled={rooms[currentPage]?.activeStep >= steps.length - 1}
                                            >
                                                ADD NEXT ROOM
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Grid>

            {/* Actions */}
            <Grid item xs={120}>
                <Grid container justifyContent="space-between">
                    {/* <Button variant="contained" color="primary" onClick={addRoom}>
                        Add Another Room
                    </Button> */}
                    {/* <Button variant="contained" color="error" onClick={removeCurrentRoom} disabled={rooms.length === 1}>
                        Remove Room
                    </Button> */}
                    {/* <Button variant="contained" color="inherit" onClick={duplicateCurrentRoom}>
                        Duplicate Room
                    </Button> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RoomForm;
