import React, { useState } from 'react';
import axios from '../lib/axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);

        try {
            const response = await axios.post('/api/create-payment-intent', {
                amount: 1000, // Adjust the amount as needed
                currency: 'usd'
            });

            const { client_secret } = response.data;

            const { error, paymentIntent } = await stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card,
                    billing_details: {
                        name: 'Customer Name',
                    },
                },
            });

            if (error) {
                setMessage(error.message);
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                setMessage('Payment succeeded!');
            }
        } catch (err) {
            console.error('Error creating payment intent:', err);
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button type="submit" disabled={!stripe}>
                Pay
            </button>
            {message && <div>{message}</div>}
        </form>
    );
};

const StripeContainer = () => (
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements>
);

export default StripeContainer;